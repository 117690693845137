<dx-form #soForm [(formData)]="model" (onDataFieldChange)="onDataFieldChange($event, soForm)"
         [alignItemLabels]="false" [alignItemLabelsInAllGroups]="false" labelLocation="left" [showColonAfterLabel]="false" [colCount]="'auto'" [minColWidth]="768"
         [elementAttr]="formAttr">

    <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [visible]="model.lastUserStatut == undefined">
        
        <dxi-item dataField="dateConstat" dataType="date" editorType="dxDateBox" [editorOptions]="dateConstatOptions()">
            <dxo-label [text]="'exigence/form-label*Statué le'|translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="dateValidite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
            <dxo-label [text]="'exigence/form-label*Statut valide jusqu'|translate"></dxo-label>
        </dxi-item>
        
        <dxi-item dataField="notes" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                [colSpan]="2">
            <dxo-label [text]="'exigence/form-label*Remarque'|translate"></dxo-label>
        </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [visible]="model.lastUserStatut != undefined">
        
        <dxi-item dataField="dateConstat" dataType="date" editorType="dxDateBox" [editorOptions]="dateConstatOptions()">
            <dxo-label [text]="'exigence/form-label*Statué le'|translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="lastUserStatut" dataType="string" editorType="dxTextBox" [editorOptions]="readonlyText">
            <dxo-label [text]="'exigence/form-label*Par'|translate"></dxo-label>
        </dxi-item>

        <dxi-item itemType="empty"></dxi-item>

        <dxi-item dataField="dateValidite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
            <dxo-label [text]="'exigence/form-label*Statut valide jusqu'|translate"></dxo-label>
        </dxi-item>
        
        <dxi-item dataField="notes" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                [colSpan]="2">
            <dxo-label [text]="'exigence/form-label*Remarque'|translate"></dxo-label>
        </dxi-item>
    </dxi-item>

    <!-- Revision -->
    <dxi-item itemType="group" [colSpan]="2" [colCount]="8"> 
        <dxi-item [colSpan]="2">
            <div *dxTemplate class="eeFormInActions">
                <dx-button [text]="'exigence/form-label*Réviser'|translate" type="success" [hint]="'exigence/form-hint*Vous considérez lexigence toujours valide'|translate"
                            (onClick)="manageRevision($event)" [visible]="canRevise()" ></dx-button>
            </div>
        </dxi-item> 
    </dxi-item>

     <dxi-item itemType="group" [colSpan]="2" [colCount]="2" [visible]="isRevised()" [caption]="'exigence/form-caption*Révision'|translate">

        <dxi-item dataField="dateRevision" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
            <dxo-label [text]="'exigence/form-caption*Sans objet révisé le'|translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="lastUserRevision" dataType="string" editorType="dxTextBox" [editorOptions]="readonlyText" [visible]="model.lastUserRevision != undefined">
            <dxo-label [text]="'exigence/form-label*Par'|translate"></dxo-label>
        </dxi-item>

    </dxi-item>
</dx-form>
