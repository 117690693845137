import { Component, OnInit } from '@angular/core';
import notify_ from 'devextreme/ui/notify';

@Component({
  selector: 'app-util',
  templateUrl: './util.component.html',
  styleUrls: ['./util.component.scss']
})
export class UtilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  static remove(arr, value) {
    return arr.filter(item => {
      return item !== value
    })
  }

}

export function notify(text: string, level?: string, timeout?: number) {
  var posY = 0;
  var allObjects = $(".dx-toast-content");

  if (allObjects.length > 0) {
    posY = 100 + allObjects.length * 60
  } else {
    posY = 100
  }

  notify_({
    message: text,
    position: { my: 'right', at: 'top right', of: window, offset: `20 ${posY} `, collision: 'flip' },
    width: "35%",
    show: { type: 'slide', duration: 400, from: { position: { my: 'top right', at: 'top right', of: window } } },
    hide: { type: 'slide', duration: 400, to: { position: { my: 'top right', at: 'top right', of: window } } },
    closeOnClick: true
  }, level, timeout)
}
