import { Component, Input, OnInit, Output } from '@angular/core';
import { WithoutObjectModel } from './without-object.model';
import editors from '../../../../common/commons.editors';
import { CommonComponent } from 'app/shared/common/common.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ee-without-object',
  templateUrl: './without-object.component.html',
  styleUrls: ['./without-object.component.scss']
})

export class WithoutObjectComponent extends CommonComponent implements OnInit {

  @Input() @Output('model') model: WithoutObjectModel
  @Input('statut') statut: number
  @Input('initialStatut') initialStatut: number

  constructor(public translate: TranslateService) { 
    super()
  }

  ngOnInit(): void {}

  onDataFieldChange(e: any, form: any){
    form[e.field] = e.value
  }

  stillSO(){
    return this.initialStatut == 4 && this.statut == 4
  }

  // Revision
  canRevise(){
    return this.stillSO()
  }

  isRevised(){
    return this.model.dateRevision !== null && this.model.dateRevision !== undefined
  }

  manageRevision(e: any){
    this.model.dateRevision = new Date()
    // For user Action
    this.event_.emit(e)
  }

}
