import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from 'app/shared/auth/auth.service';
import { AppConfig } from 'app/app.config';

@Injectable({  providedIn: 'root' })
export class AdminAuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const isAdmin = this.auth.isAdminUserPersisted();

    if(AppConfig.settings.trace)  console.log("AdminAuthGuard:canActivate, isAdmin="+isAdmin);
    if (isAdmin) {
      return true;
    } else {
      this.router.navigate(['pages/admin-login']);
      return false;
    }

  }
}
