import { CommonModel } from 'app/shared/common/common.model';

export class WithoutObjectModel extends CommonModel {

    dateConstat: Date = new Date()
    dateValidite: Date

    dateRevision: Date = null

    constructor(){
        super()
    }

    init(_currentEE: any){

        this.dateConstat = _currentEE.dateConstat
        this.notes = _currentEE.notes
        this.dateValidite = _currentEE.dateValidite 

        this.dateRevision = _currentEE.dateRevision
 
        super.initCommon(_currentEE)

        return this
    }
    
}