import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { QSE_USER_INFO, QSE_SITE_CONTRAT, QSE_LAST_SITE } from './services/user.service';
import { userHeaderInfo } from './services/commons';
import { AppConfig } from './app.config';

@Injectable( { providedIn: 'root' } )
export class SiteChangeService {

  private subject = new Subject<any>();
//  private subjectAudit = new Subject<any>();
  
  private lastSelectedSite: number = 0;
  public  lastSiteName: string = "";
  constructor() { }

  getSiteChangeMessage(): Observable<any> {
    return this.subject.asObservable();
  }

/*  getSiteAuditChangeMessage(): Observable<any> {
    return this.subjectAudit.asObservable();
  }
*/
  public announceSiteChange(newsite: number) {
  // if(AppConfig.settings.trace) console.log("SiteChangeService : announceSiteChange newsite=" + newsite);
    this.refreshContrat(newsite);
    
    sessionStorage.setItem(QSE_LAST_SITE, String(newsite));
    this.lastSelectedSite = newsite;
    this.subject.next(newsite);
 //   this.subjectAudit.next(newsite);
  }

  public getLastSelectedSite() {
    return this.lastSelectedSite;
  }

  public refreshContrat(site) {
    var QUI = JSON.parse(sessionStorage.getItem(QSE_USER_INFO));
    QUI.selectSite = site;
      var sites = QUI.sites;
      var s:any;
      for(var i=0; i<sites.length; i++) {
        s = sites[i];
        if(s.id==site) {
          userHeaderInfo[4] = s.contrat;
          sessionStorage.setItem(QSE_SITE_CONTRAT, s.contrat);
          break;
        }
      }
  }


}
