import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { userHeaderInfo } from 'app/services/commons';
import editors from './commons.editors'

@Component({
  selector: 'app-ee-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss']
})
export class CommonComponent implements OnInit {

  @Input() currentSite: any
  @Input() public editMode: number
  @Input() formAttr: any
  @Output('onRevision') event_: EventEmitter<any> = new EventEmitter<any>();
  public iso: boolean

  // Template editors
  fourItemsOptions = editors.fourItemsOptions
  readOnlyNumberOptions = editors.readOnlyNumberOptions
  numberOptions = editors.numberOptions
  percentNumberOptions = editors.percentNumberOptions
  amountNumberOptions = editors.amountNumberOptions

  readOnlyDateOptions = editors.readOnlyDateOptions
  dateOptions = editors.dateOptions
  initDateOptions = editors.initDateOptions
  textAreaOptions = editors.textAreaOptions 
  readonlyText = editors.readonlyText

  constructor() { }

  ngOnInit(): void {
    this.iso = this.editMode == 3
  }

  dateConstatOptions(){
    return userHeaderInfo[0] == 'A' ? this.dateOptions : this.readOnlyDateOptions
  }

}
