  <dx-form #eeForm_, id="eeForm" [(formData)]="eeModel" showColonAfterLabel="false" onDataFieldChange="onDataFieldChange($event, eeForm)" [colCount]="1"
          [disabled]="disabled || internalDisabled">

    <dxi-item>
      <div *dxTemplate>
      <app-ee-status [eeModelId]="eeModel.id" [(statut)]="eeModel.statut" [minimal]="minimal" (onValueChangedAction)="onStatusValueChanged($event)"
                     [popUpContainer]="popUpContainer"></app-ee-status> 
      </div>
    </dxi-item>

    <dxi-item [visible]="eeModel.isToEvaluate()">
      <div *dxTemplate [ngClass]="minimalClassIf()">
        <app-ee-to-evaluate [(model)]="eeModel.toEvaluateModel"
                            [formAttr]="formAttr()"
                            [statut]="eeModel.statut"
                            [initialStatut]="eeModel.initialStatut">
        </app-ee-to-evaluate>
      </div>
    </dxi-item>

    <dxi-item [visible]="eeModel.isCompliant()">
      <div *dxTemplate [ngClass]="minimalClassIf()">
        <app-ee-compliant [(model)]="eeModel.compliantModel" [ncModel]="eeModel.notCompliantModel" [avModel]="eeModel.toCheckModel"
                          [statut]="eeModel.statut" [initialStatut]="eeModel.initialStatut"
                          [editMode]="editMode" [currentSite]="currentSite"  
                          [formAttr]="formAttr()" (onRevision)="onRevision($event)" (onOpportunity)="onOpportunity($event)">
        </app-ee-compliant>
      </div>
    </dxi-item>

    <dxi-item [visible]="eeModel.isNotCompliant()">
      <div *dxTemplate [ngClass]="minimalClassIf()">
        <app-ee-not-compliant [(model)]="eeModel.notCompliantModel" [statut]="eeModel.statut" [initialStatut]="eeModel.initialStatut"
                              [editMode]="editMode"[currentSite]="currentSite" 
                              [formAttr]="formAttr()">
        </app-ee-not-compliant>
      </div>
    </dxi-item>

    <dxi-item [visible]="eeModel.isWithoutObject()">
      <div *dxTemplate [ngClass]="minimalClassIf()">
        <app-ee-without-object [(model)]="eeModel.withoutObjectModel" [statut]="eeModel.statut" [initialStatut]="eeModel.initialStatut"
                               [formAttr]="formAttr()" (onRevision)="onRevision($event)">
        </app-ee-without-object>
      </div>
    </dxi-item>

    <dxi-item [visible]="eeModel.isToCheck()">
      <div *dxTemplate [ngClass]="minimalClassIf()">
        <app-ee-to-check [(model)]="eeModel.toCheckModel" [statut]="eeModel.statut" [initialStatut]="eeModel.initialStatut"
                         [editMode]="editMode"[currentSite]="currentSite" 
                         [formAttr]="formAttr()">
        </app-ee-to-check>
      </div>
    </dxi-item>

    <dxi-item dataField="eeModel.userActions" dataType="[string]" [visible]="false"></dxi-item>

</dx-form>

<div *ngIf="showButtons()" [ngClass]="formButtonsClass()">
    <dx-button [text]="'exigence/form-action*save'|translate" type="success" (onClick)="onSubmit($event, false)"></dx-button>

    <dx-button id="saveAllButton" [text]="'exigence/form-action*save-multi'|translate:{'ne': currentReferenceUsage !== undefined ? currentReferenceUsage.eecount : ''}" 
        type="danger"[visible]="showSaveAllButton()" 
        (onClick)="onSubmitAll($event, true)"></dx-button>

    <dx-button id="saveAllSiteButton" [text]="'exigence/form-action*save-allsites'|translate" 
        type="danger"[visible]="showSaveAllSiteButton()" 
        (onClick)="onSubmitSiteAll($event, true, false)"></dx-button>
    <dx-button id="saveAllSiteIfButton" [text]="'exigence/form-action*save-allsites-notyetevaluated'|translate" 
        type="danger"[visible]="showSaveAllSiteButton()" 
        (onClick)="onSubmitSiteAll($event, true, true)"></dx-button>

    <dx-button [text]="'exigence/form-action*clear'|translate" type="normal" (onClick)="onCancel($event)"></dx-button> 
</div>