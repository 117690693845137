<dx-form #ncForm [(formData)]="model" (onDataFieldChange)="onDataFieldChange($event, ncForm)"
         [alignItemLabels]="false" [alignItemLabelsInAllGroups]="false" labelLocation="left" [showColonAfterLabel]="false" [colCount]="'auto'" 
         [minColWidth]="768" [elementAttr]="formAttr">

    <!-- No Conformity --> 
    <dxi-item itemType="group"  [colSpan]="2" [colCount]="2" [visible]="model.lastUserStatut == undefined">

        <dxi-item dataField="dateConstat" dataType="date" editorType="dxDateBox" [editorOptions]="dateConstatOptions()">
            <dxo-label [text]="'exigence/form-label*Statué le'|translate"></dxo-label>
        </dxi-item>

        <dxi-item [colSpan]="1">
            <div *dxTemplate>
                <dx-check-box [(value)]="model.modeleAction"></dx-check-box>
            </div>
            <dxo-label [text]="'exigence/form-caption*Modèle daction'|translate"></dxo-label>
        </dxi-item> 

    </dxi-item>

    <dxi-item itemType="group"  [colSpan]="2" [colCount]="2" [visible]="model.lastUserStatut != undefined">

        <dxi-item dataField="dateConstat" dataType="date" editorType="dxDateBox" [editorOptions]="dateConstatOptions()">
            <dxo-label [text]="'exigence/form-label*Statué le'|translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="lastUserStatut" dataType="string" editorType="dxTextBox" [editorOptions]="readonlyText">
            <dxo-label [text]="'exigence/form-label*Par'|translate"></dxo-label>
        </dxi-item>

        <dxi-item itemType="empty"></dxi-item>

        <dxi-item [colSpan]="1">
            <div *dxTemplate>
                <dx-check-box [(value)]="model.modeleAction"></dx-check-box>
            </div>
            <dxo-label [text]="'exigence/form-caption*Modèle daction'|translate"></dxo-label>
        </dxi-item> 

    </dxi-item>

    <!-- Action plan -->
    <dxi-item itemType="group"  [colSpan]="2" [colCount]="2" [caption]="'exigence/form-caption*Plan dactions'|translate">

        <dxi-item dataField="actions" editorType="dxTextArea" [editorOptions]="textAreaOptions"
                    [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Action'|translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="notes" editorType="dxTextArea" [editorOptions]="textAreaOptions" 
                    [colSpan]="2">
                <dxo-label [text]="'exigence/form-label*Remarque'|translate"></dxo-label>
        </dxi-item>

        <dxi-item [colSpan]="1" dataField="priorite" editorType="dxSelectBox" [editorOptions]="fourItemsOptions">
            <dxo-label [text]="'exigence/form-label*Priorité'|translate"></dxo-label>
        </dxi-item>

        <dxi-item dataField="facilite" editorType="dxCheckBox" >
            <dxo-label [text]="'exigence/form-caption*Facilité de mise en oeuvre'|translate"></dxo-label>
        </dxi-item>

        <dxi-item [colSpan]="1" dataField="responsable">
            <div *dxTemplate="let data of 'respTemplate'"> 
                <app-responsable-selecteur [selectedSite]="currentSite" 
                                    [selectedResponsable]="data.component.option('formData')[data.dataField]"
                                    (responsableUpdated)="onResponsibleUpdated($event)">
                </app-responsable-selecteur> 
              </div>
              <dxo-label [text]="'exigence/form-label*Responsable de laction'|translate"></dxo-label>
        </dxi-item>
       
        <dxi-item [colSpan]="1">
            <div *dxTemplate>
                <dx-check-box [(value)]="model.notifyResponsible" (onValueChanged)="checkResponsible()"></dx-check-box>
            </div>
            <dxo-label [text]="'exigence/form-label*Notifier le responsable'|translate"></dxo-label>
        </dxi-item> 


        <dxi-item dataField="dateLimite" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
            <dxo-label [text]="'exigence/form-caption*Date limite'|translate"></dxo-label>
        </dxi-item>

    </dxi-item>
   
    <!-- ISO fields -->
    <dxi-item itemType="group"  [colSpan]="2" [colCount]="2" [visible]="iso" [caption]="'exigence/form-caption*ISO'|translate">
        <dxi-item itemType="group"  [colSpan]="2" [colCount]="3">

            <dxi-item dataField="moyens" editorType="dxTextArea" [editorOptions]="textAreaOptions" 
                        [colSpan]="3">
                    <dxo-label [text]="'exigence/form-label*Moyen'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="frequence" editorType="dxSelectBox" [editorOptions]="fourItemsOptions">
                <dxo-label [text]="'exigence/form-caption*Fréquence de la non conformité'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="gravite" editorType="dxSelectBox" [editorOptions]="fourItemsOptions">
                <dxo-label [text]="'exigence/form-caption*Gravité de la non conformité'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1">
                <dx-number-box id="hierarchisationField" [value]="model.gravite * model.frequence * model.priorite" [disabled]="true"
                    ></dx-number-box>
                <dxo-label [text]="'exigence/form-caption*Hiérarchisation de la non conformité'|translate"></dxo-label>
            </dxi-item> 

        </dxi-item>

        <dxi-item itemType="group"  [colSpan]="2" [colCount]="2">

            <dxi-item [colSpan]="1" dataField="montant" editorType="dxNumberBox" [editorOptions]="amountNumberOptions">
                <dxo-label [text]="'exigence/form-label*Coût de laction'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="service">
                <div *dxTemplate="let data of 'serviceTemplate'">
                    <app-service-selecteur [selectedSite]="currentSite"  
                                        [selectedService]="data.component.option('formData')[data.dataField]" 
                                        (serviceUpdated)="onServiceUpdated($event)">
                    </app-service-selecteur>
                </div>
                <dxo-label [text]="'exigence/form-label*Service'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="niveauRealisation" editorType="dxNumberBox" [editorOptions]="percentNumberOptions">
                <dxo-label [text]="'exigence/form-caption*Niveau de réalisation de laction'|translate"></dxo-label>
            </dxi-item>

            <dxi-item [colSpan]="1" dataField="dateRealisation" dataType="date" editorType="dxDateBox" [editorOptions]="dateOptions">
                <dxo-label [text]="'exigence/form-caption*Au'|translate"></dxo-label>
            </dxi-item>

        </dxi-item>
    </dxi-item>

</dx-form>
